import React from "react";

export default function App() {
  return (
    <div className="bg-white h-screen flex items-start justify-start text-sm md:text-base">
      <div className="bg-black h-full mr-auto w-6 md:w-12" />
      <div className="pt-8 mr-auto">
        <img
          className="w-24 md:w-64"
          alt="r3s-dev logo"
          src="/assets/images/r3s.png"
        />
      </div>
      <div className="pt-8 flex flex-col justify-center items-end mr-auto font-['Touche-Regular']"></div>
    </div>
  );
}
